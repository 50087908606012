import { useState, useEffect } from 'react';
import Countdown from "react-countdown";

const Complete = () => <div className="align-middle">Eljött a nap! 🤩</div>;
const renderer = ({ days, hours, minutes, seconds, completed, props }) => {
    return (
      <div className="col-12 col-lg-3">
        <div className="countdown card mb-3 {completed ? border-success : border-primary}">
            {completed ? <div className="bg-success countdown-title card-header text-white text-center fw-bold">{props.title}</div> : <div className="bg-primary countdown-title card-header text-white text-center fw-bold">{props.title}</div>}
            {completed 
            ? 
              <span className="countdown-timer-timeout text-success card-body"><Complete /></span> 
            : 
              <div className="countdown-timer card-body">
                  {/* {days} nap, {hours} óra, {minutes} perc, {seconds} másodperc */}
                  <div className="countdown">{days}<span>nap</span></div>
                  <p>:</p>
                  <div className="countdown">{hours}<span>óra</span></div>
                  <p>:</p>
                  <div className="countdown">{minutes}<span>perc</span></div>
                  <p>:</p>
                  <div className="countdown">{seconds}<span>másodperc</span></div>
              </div>
            }
            <small className="card-footer py-0 text-center text-secondary">{new Date(props.date - (props.date.getTimezoneOffset() * 60000)).toISOString().replace("T"," ").replace("Z","").split(".")[0]}</small>
        </div>
      </div>
    );
};


const Header = () => <h4 className="bg-primary rounded text-white d-lg-inline-block text-center text-lg-left py-1 px-3 mb-4">Capybara visszaszámláló</h4>
const Footer = () => <h6 className="rounded text-light d-inline-flex float-end px-1">By, krida</h6>

const Fetch = () => {
    const [myData, setMyData] = useState([]);
    useEffect(() => {
      fetch('./data.json')
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setMyData(data);
        });
    }, []);

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1)
        
    return (    
        <div className="container pt-3 pt-lg-5 pb-5 px-lg-5">
            <Header />
            <div className="row">
                {myData.map((item) => {
                    if (new Date(item.date) > yesterday) return (<Countdown key={item.name} title={item.name} date={new Date(item.date)} renderer={renderer} />)
                })}
            </div>
            <Footer />
        </div>
    );
};

export default Fetch;
