import { StrictMode } from 'react';
import Countdown from "./capybara";

function App() {
  return (
    <StrictMode>
      <Countdown />
    </StrictMode>
  );
}

export default App;
